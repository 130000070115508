<template>
  <div class="wish-container">
    <WishSearch />
    <PopularCompanies />
    <AllCompanies />
  </div>
  <a class="logo-ads" href="https://clearbit.com">Logos provided by Clearbit</a>
</template>

<script>
import WishSearch from "@/components/WishList/wishSearch.vue";
import PopularCompanies from "@/components/WishList/popularCompanies.vue";
import AllCompanies from "@/components/WishList/allCompanies.vue";
export default {
  components: {
    WishSearch,
    PopularCompanies,
    AllCompanies,
  },
  async setup() {

  }
}
</script>

<style scoped>
.wish-container{
  width: 90%;
  margin: 0 auto 50vh auto;
}

@media screen and (max-width: 600px) {
  .wish-container{
    width: calc(100% - 10px);
    margin: 0 5px;
  }
}

.logo-ads {
  position: relative;
  color: black;
  text-align: center;
  bottom: 0;
  width: 250px;
  display: block;
  margin: 0 auto;
}
</style>
