<template>
  <div v-if="isLoaded" class="OuterBox">
    <h2>Start by searching for your target company</h2>
    <div class="search-bar" @focusin="toggleIsSearching(true)"
         @focusout="toggleIsSearching(false)">
      <div>
        <ion-icon class="small-icon" name="search-outline"></ion-icon>
      </div>
      <input
          v-model="search"
          placeholder="Search job title, companies, etc."
          type="text"
      />
    </div>
    <ul
        v-show="isSearching && search != '' && searchAllCompanies.length > 0"
        class="search-results"
    >
      <CompanyCard
          v-for="(company, index) in searchAllCompanies"
          :key="company"
          :class="{ 'first-item': index == 0 && false }"
          :company="company"
          @click="handleClickSearch(company)"
      />
    </ul>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {onMounted, ref, watchEffect} from "vue";
import getAllCompanies from "@/composables/WishList/getAllCompanies";
import CompanyCard from "@/components/WishList/companyCard.vue";
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  components: {
    CompanyCard,
  },
  async setup() {
    const router = useRouter();
    const allCompanies = ref([]);
    const isSearching = ref(false);
    const searchAllCompanies = ref(null);
    const search = ref("");
    const noSpecial = ref(null);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const isLoaded = ref(false);

    onMounted(async () => {
      await getAllCompanies(allCompanies);
      isLoaded.value = true;
    });

    const enterSpecialCharAlert = () => {
      createToast({
        title: "Error when searching",
        description: 'No special characters allowed in search.'
      })
    }

    // allCompanies.value.forEach(firm => console.log(firm.name));

    watchEffect(() => {
      searchAllCompanies.value = allCompanies.value;

      searchAllCompanies.value = searchAllCompanies.value.filter((company) => {
        // console.log(company.name);
        if (search.value == "") {
          return false;
        } else if (noSpecial.value.test(search.value)) {
          enterSpecialCharAlert()
          search.value = "";
          return false;
        }
        return (company.name.toLowerCase().match(search.value.toLowerCase()));
      });

      searchAllCompanies.value = searchAllCompanies.value.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    });

    const toggleIsSearching = (status) => {
      if (status) {
        isSearching.value = true;
      } else {
        setTimeout(() => {
          isSearching.value = false;
        }, 500);
      }
      // console.log(search.value);
    };

    const handleClickSearch = (company) => {
      router.push({name: "Junior-WishCompany", params: {companyId: company.id, companyName: company.name}});
    }

    return {
      allCompanies,
      isSearching,
      isLoaded,
      searchAllCompanies,
      search,
      noSpecial,
      toggleIsSearching,
      handleClickSearch,
      enterSpecialCharAlert
    };
  }
}
</script>

<style scoped>
.OuterBox {
  position: relative;
  width: 100%;
  height: 35vh;
  border-radius: 2vh;
  margin-top: 5vh;
  animation: fadein 1s;
  background-color: white;
  /* https://getwaves.io */
  background-image: url('../../images/wave.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .OuterBox {
    margin-top: 10px;
    background-color: var(--blue);
  }
}

.OuterBox h2 {
  font-weight: bold;
  font-size: var(--subheader);
  color: var(--purple);
  text-align: center;
  padding: 9vh 0 6vh 0;
}

.search-bar {
  height: 8vh;
  width: 70%;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  border-radius: 10px;
  min-width: 200px;
  padding: 10px;
}

.search-bar div {
  flex: 0 1 10%;
  background: #FFFFFF;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 80%;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.search-bar input,
.search-bar input:focus {
  display: block;
  flex: 1 0 auto;
  background: #FFFFFF;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 4vh;
  border: none;
  outline: none;
}

.search-results {
  position: absolute;
  z-index: 10;
  top: 26vh;
  left: 15%;
  padding: 0 0;
  border-radius: 10px;
  max-height: 50vh;
  width: 70%;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.search-results::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 600px) {
  .search-bar {
    margin-top: 20px;
  }

  .search-results {
    top: 32vh;
  }
}
</style>
